.App {
  text-align: center;
  font-family: 'PT Sans', sans-serif;
}
.li {
  cursor: pointer;
}

p {
 font-size: 17px;
}
.bg-warning {
  background: #c84e26 !important
}

.bg-success {
  background: #00af60 !important
}

.navbar-grad-top {
  background-image: linear-gradient(rgba(0, 0, 0, .6), transparent);
}

.navbar-grad-right {
  background-image: linear-gradient(to left, rgba(0, 0, 0, .55), transparent 60%);
}
.nav-link:hover {
  color: #c84e26 !important;
  background: rgba(0, 0, 0, .3);
  background-position: center center;
}

.link_style.active {
  color: #0a69ba !important;
  border-right: 4px solid #0a69ba;
}

.pill-style.active {
  color:#ffffff;
  background-color: #0a69ba !important;
  border-radius: 0px;
}

.pill-style:hover {
  color:#ffffff !important;
  background-color: #0a69ba !important;
  border-radius: 0px;
}

header .nav-link {
  padding-top: 29px;
  font-size: 12px;
  height: 70px
}

header .nav-link:hover{
  color: #FFFFFF !important;
  background: rgba(0, 0, 0, .3);
  background-position: center center;
}

/*
.nav-pills .nav-link:active {
  background-color: rgba(255, 0, 0, 0.5);
}
*/
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  border-radius: 0px;
  color: #FFFFFF;
  background-color: rgba(0, 0, 0, .5);
  
}

.nav-pills .nav-link:hover {
  border-radius: 0px;
  color: #FFFFFF;
  background-color: rgba(0, 0, 0, .5);
  
}

/*
.filmstrip{
  list-style: none;
  cursor: pointer;
}

.filmstrip li{
  display: inline-block;
  width: 100%;
}

.filmstrip li:hover{
  border: 2px solid rgb(255, 255, 255);
}
*/

.carousel-indicators li {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}
.carousel-indicators {
  bottom: -50px;
}

.accordion .card-header h4:after {
  /*font-family: 'Font Awesome 5 Solid';  
  content: "\e114";
*/
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f106";
  float: right;
  transition: all 0.5s;

}
.accordion .card-header.collapsed h4:after {
  /* symbol for "collapsed" panels */
  /*content: "\f107"; */
  -webkit-transform: rotate(180deg);
	-moz-transform: rotate(180deg);
	transform: rotate(180deg); 
}

.product-button {
  background-color:#0a69ba;
  color: white;
  font-size: 17px;
  border-radius: 10px;
  min-height: 40px;
}

.cons-button {
  background-color:#0a69ba;
  color: white;
  padding: 5px 20px !important;
  font-size: 17px;
  border-radius: 0px;
  min-height: 24px;
}

.pagebgimg {
    background-size: cover !important;/*cover !important; */
    margin-top: -115px !important;
    background-repeat: no-repeat !important;
    background-attachment: scroll !important;  
}
/*
@media only screen and (min-width: 600px) {
 .pagebgimg {
  background-attachment: fixed;
  height: 100%;
 }
}
*/

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
 /* header .nav-link {
    padding-top: 18px;
    font-size: 12px;
    height: 40px;
    background-color: rgba(0, 0, 0, .5) !important;
  }
  header .nav-link:hover{
    color: #FFFFFF !important;
    background: rgba(0, 0, 0, .3);
    background-position: center center;
  } */
  .homepagebg {
    background-image: url('/img/pages/Interstar_landing page_600.jpg');
    min-height: 50vh !important;
    background-position: 0px 0px !important;
  }
  .aboutpagebg {
    background-image: url('/img/pages/Interstar_About Us_page_600.jpg');
    min-height: 50vh !important;
    background-position: 0px 0px !important;
  }
  .architectpagebg {
    background-image: url('/img/pages/Interstar_Architects_Designers_page_600.jpg');
    min-height: 50vh !important;
    background-position: 0px 0px !important;
  }
  .chartpagebg {
    background-image: url('/img/pages/Interstar_Color Charts_page_600.jpg');
    min-height: 50vh !important;
    background-position: 0px 0px !important;
  }
  .profpagebg {
    background-image: url('/img/pages/Interstar_Professionals_page_600.jpg');
    min-height: 50vh !important;
    background-position: 0px 0px !important;
  }
  .conspagebg {
    background-image: url('/img/pages/Interstar_Consumers_page_600.jpg');
    min-height: 50vh !important;
    background-position: 0px 0px !important;
  }
  .servpagebg {
    background-image: url('/img/pages/Interstar_Services_page_600.jpg');
    min-height: 50vh !important;
    background-position: 0px 0px !important;
  }
  .faqpagebg {
    background-image: url('/img/pages/Interstar_FAQ_page_600.jpg');
    min-height: 50vh !important;
    background-position: 0px 0px !important;
  }
  .downloadpagebg {
    background-image: url('/img/pages/Interstar_Downloads_page_600.jpg');
    min-height: 50vh !important;
    background-position: 0px 0px !important;
  }
  .markpagebg {
    background-image: url('/img/pages/Interstar_Marketing_landing_page_600.jpg');
    min-height: 50vh !important;
    background-position: 0px 0px !important;
  }
  .titleblock-header {
    margin-top: 30vh !important;
  }
  .titleblock-headerMK {
    margin-top: 30vh !important;
  }
} 

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
/*  header .nav-link {
    padding-top: 18px;
    font-size: 12px;
    height: 40px;
    background-color: rgba(0, 0, 0, .5) !important;
  }
  header .nav-link:hover{
    color: #FFFFFF !important;
    background: rgba(0, 0, 0, .3);
    background-position: center center;
  } */
  .homepagebg {
    background-image: url('/img/pages/Interstar_landing page_768.jpg');
    min-height: 60vh !important;
    background-position: 0px -50px !important;
  }
  .aboutpagebg {
    background-image: url('/img/pages/Interstar_About Us_page_768.jpg');
    min-height: 60vh !important;
    background-position: 0px -50px !important;
  }
  .architectpagebg {
    background-image: url('/img/pages/Interstar_Architects_Designers_page_768.jpg');
    min-height: 60vh !important;
    background-position: 0px -50px !important;
  }
  .chartpagebg {
    background-image: url('/img/pages/Interstar_Color Charts_page_768.jpg');
    min-height: 60vh !important;
    background-position: 0px -50px !important;
  }
  .profpagebg {
    background-image: url('/img/pages/Interstar_Professionals_page_768.jpg');
    min-height: 60vh !important;
    background-position: 0px -50px !important;
  }
  .conspagebg {
    background-image: url('/img/pages/Interstar_Consumers_page_768.jpg');
    min-height: 60vh !important;
    background-position: 0px -50px !important;
  }
  .servpagebg {
    background-image: url('/img/pages/Interstar_Services_page_768.jpg');
    min-height: 60vh !important;
    background-position: 0px -50px !important;
  }
  .faqpagebg {
    background-image: url('/img/pages/Interstar_FAQ_page_768.jpg');
    min-height: 60vh !important;
    background-position: 0px -50px !important;
  }
  .downloadpagebg {
    background-image: url('/img/pages/Interstar_Downloads_page_768.jpg');
    min-height: 60vh !important;
    background-position: 0px -50px !important;
  }
  .markpagebg {
    background-image: url('/img/pages/Interstar_Marketing_landing_page_768.jpg');
    min-height: 60vh !important;
    background-position: 0px -50px !important;
  }
  .titleblock-header {
    margin-top: 40vh !important;
  }
  .titleblock-headerMK {
    margin-top: 40vh !important;
  }
} 

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
 /* header .nav-link {
    padding-top: 18px;
    font-size: 12px;
    height: 40px;
    background-color: rgba(0, 0, 0, .5) !important;
  }
  header .nav-link:hover{
    color: #FFFFFF !important;
    background: rgba(0, 0, 0, .3);
    background-position: center center;
  } */
  .homepagebg {
    background-image: url('/img/pages/Interstar_landing page_991.jpg');
    min-height: 70vh !important;
    background-position: 0px -50px !important;
  }
  .aboutpagebg {
    background-image: url('/img/pages/Interstar_About Us_page_991.jpg');
    min-height: 70vh !important;
    background-position: 0px -50px !important;
  }
  .architectpagebg {
    background-image: url('/img/pages/Interstar_Architects_Designers_page_991.jpg');
    min-height: 70vh !important;
    background-position: 0px -50px !important;
  }
  .chartpagebg {
    background-image: url('/img/pages/Interstar_Color Charts_page_991.jpg');
  min-height: 70vh !important;
  background-position: 0px -50px !important;
  }
  .profpagebg {
    background-image: url('/img/pages/Interstar_Professionals_page_991.jpg');
    min-height: 70vh !important;
    background-position: 0px -50px !important;
  }
  .conspagebg {
    background-image: url('/img/pages/Interstar_Consumers_page_991.jpg');
    min-height: 70vh !important;
    background-position: 0px -50px !important;
  }
  .servpagebg {
    background-image: url('/img/pages/Interstar_Services_page_991.jpg');
    min-height: 70vh !important;
    background-position: 0px -50px !important;
  }
  .faqpagebg {
    background-image: url('/img/pages/Interstar_FAQ_page_991.jpg');
    min-height: 70vh !important;
    background-position: 0px -50px !important;
  }
  .downloadpagebg {
    background-image: url('/img/pages/Interstar_Downloads_page_991.jpg');
    min-height: 70vh !important;
    background-position: 0px -50px !important;
  }
  .markpagebg {
    background-image: url('/img/pages/Interstar_Marketing_landing_page_991.jpg');
    min-height: 70vh !important;
    background-position: 0px -150px !important;
  }
  .titleblock-header {
    margin-top: 40vh !important;
  }
  .titleblock-headerMK {
    margin-top: 40vh !important;
  }
} 

@media only screen and (max-width: 991px) {
  header .nav-link {
    padding-top: 18px;
    font-size: 12px;
    height: 40px;
    background-color: rgba(0, 0, 0, .5) !important;
  }
  header .nav-link:hover{
    color: #FFFFFF !important;
    background: rgba(0, 0, 0, .3);
    background-position: center center;
  }
}
/* Large devices (laptops/desktops, 992px and up)
@media only screen and (min-width: 992px) {
  header .nav-link {
    padding-top: 29px;
    font-size: 12px;
    height: 70px;
    background-color: transparent !important;
  }
  header .nav-link:hover{
    color: #FFFFFF !important;
    background: rgba(0, 0, 0, .3);
    background-position: center center;
  }
} 

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
 /* header .nav-link {
    padding-top: 29px;
    font-size: 12px;
    height: 70px;
    background-color: transparent !important;
  }
  header .nav-link:hover{
    color: #FFFFFF !important;
    background: rgba(0, 0, 0, .3);
    background-position: center center;
  }*/
  .homepagebg {
    background-image: url('/img/pages/Interstar_landing page_1920.jpg');
    /*background-image: url('/img/pages/Interstar_landing page_new.jpf'); */
    min-height: 70vh !important;
    background-position: 0px -250px !important;
  }
  .aboutpagebg {
    background-image: url('/img/pages/Interstar_About Us_page_1920.jpg');
    min-height: 70vh !important;
    background-position: 0px -270px !important;
  }
  .architectpagebg {
    background-image: url('/img/pages/Interstar_Architects_Designers_page_1920.jpg');
    min-height: 70vh !important;
    background-position: 0px -250px !important;
  }
  .chartpagebg {
    background-image: url('/img/pages/Interstar_Color Charts_page_1920.jpg');
    min-height: 70vh !important;
    background-position: 0px -250px !important;
  }
  .profpagebg {
    background-image: url('/img/pages/Interstar_Professionals_page_1920.jpg');
    min-height: 70vh !important;
    background-position: 0px -270px !important;
  }
  .conspagebg {
    background-image: url('/img/pages/Interstar_Consumers_page_1920.jpg');
    min-height: 70vh !important;
    background-position: 0px -300px !important;
  }
  .servpagebg {
    background-image: url('/img/pages/Interstar_Services_page_1920.jpg');
    min-height: 70vh !important;
    background-position: 0px -250px !important;
  }
  .faqpagebg {
    background-image: url('/img/pages/Interstar_FAQ_page_1920.jpg');
    min-height: 70vh !important;
    background-position: 0px -250px !important;
  }
  .downloadpagebg {
    background-image: url('/img/pages/Interstar_Downloads_page_1920.jpg');
    min-height: 70vh !important;
    background-position: 0px -250px !important;
  }
  .markpagebg {
    background-image: url('/img/pages/Interstar_Marketing_landing_page_1920.jpg');
    min-height: 70vh !important;
    background-position: 0px -250px !important;
  }
  .titleblock-header {
    margin-top: 50vh !important;
  }
  .titleblock-headerMK {
    margin-top: 50vh !important;
  }
}

@media only screen and (min-width: 1600px) {
  .markpagebg {
    background-image: url('/img/pages/Interstar_Marketing_landing_page_1920.jpg');
    min-height: 70vh !important;
    background-position: 0px -425px !important;
  }
  .titleblock-headerMK {
    margin-top: 50vh !important;
  }
}

.dialog-ovelay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.50);
  z-index: 999999
}
.dialog-ovelay .dialog {
  width: 400px;
  margin: 100px auto 0;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0,0,0,.2);
  border-radius: 3px;
  overflow: hidden
}
.dialog-ovelay .dialog header {
  padding: 10px 8px;
  background-color: #f6f7f9;
  border-bottom: 1px solid #e5e5e5
}
.dialog-ovelay .dialog header h3 {
  font-size: 14px;
  margin: 0;
  color: #555;
  display: inline-block
}
.dialog-ovelay .dialog header .fa-times {
  float: right;
  color: #c4c5c7;
  cursor: pointer;
  transition: all .5s ease;
  padding: 0 2px;
  border-radius: 1px    
}
.dialog-ovelay .dialog header .fa-times:hover {
  color: #b9b9b9
}
.dialog-ovelay .dialog header .fa-times:active {
  box-shadow: 0 0 5px #673AB7;
  color: #a2a2a2
}
.dialog-ovelay .dialog .dialog-msg {
  padding: 12px 10px
}
.dialog-ovelay .dialog .dialog-msg p{
  margin: 0;
  font-size: 15px;
  color: #333
}
.dialog-ovelay .dialog footer {
  border-top: 1px solid #e5e5e5;
  padding: 8px 10px
}
.dialog-ovelay .dialog footer .controls {
  direction: rtl
}
.dialog-ovelay .dialog footer .controls .button {
  padding: 5px 15px;
  border-radius: 3px
}
.button {
cursor: pointer
}
.button-default {
  background-color: rgb(248, 248, 248);
  border: 1px solid rgba(204, 204, 204, 0.5);
  color: #5D5D5D;
}
.button-danger {
  background-color: #f44336;
  border: 1px solid #d32f2f;
  color: #f5f5f5
}
.link {
padding: 5px 10px;
cursor: pointer
}

.list-middot > li:not(:last-child)::after {
  content: " •";
}

.scroll-to-top {
  position: fixed;
  bottom: 0.7rem;
  right: 0.7rem;
  animation: fadeIn 700ms ease-in-out 1s both;
  cursor: pointer;
  background-color:#0a69ba;
  color: white;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}